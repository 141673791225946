@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
      background: #000;
    }
    body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background: #000;
        background: linear-gradient(#1A1A1A 0%, #000 540px);
        min-height: 100vh;
    }

    .fslightbox-open .headroom-wrapper {
      z-index: 0!important;
    }

    main {
      overflow-x: hidden;
      flex: 1;
    }

    .display-silver-screen {
      background-color: #C34949;
      color: #000;
      font-weight: 900;
      font-size: 16px;
      padding: 8px 0;
      text-align: center;
      width: 100%;
      margin: 0 auto 10px;
      max-width: 1200px;
      letter-spacing: 16px;
      box-shadow: 0px 8px 16px rgba(195, 73, 73, 0.5);
    }

    .display-info {
      margin-top: 100px;
      background-color: #C4C4C4;
      color: #000000;
      width: 100%;
      margin: 20px auto 30px;
      max-width: 1200px;
      padding: 40px 50px;
      display: flex;
      flex-direction: column;
    }

    .display-info-top {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      border-bottom: 2px solid #000;
      align-items: center;
      padding-bottom: 20px;
    }

    .display-info-showtime {
      font-size: 32px;
      font-weight: bold;
      line-height: 38px;
      color: #000;
      text-align: right;
    }

    .display-info-cinema {
      font-weight: 900;
      font-size: 72px;
      line-height: 86px;
      width: 50%;
      text-align: left;
    }

    .display-info-movie-name {
      padding-top: 20px;
      width: 100%;
      /* font-size: clamp(20px, 20vw, 100px); */
      font-size: 48px;
      line-height: 58px;
      font-weight: 700;
      text-align: left;
      align-self: center;
      overflow: hidden !important;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .display-show-info {
      /* font-size: clamp(20px, 80px, 50px); */
      /* text-align: right; */
    }

    .display-show-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media (max-width: 767px) {
      .seat-plan-wrapper {
        width: 100%;
        @apply flex flex-row flex-wrap;
        font-size: 8px;
      }

      .seat-block {
        @apply flex justify-center content-center;
        padding: 2px;
      }

      .seat-block-span {
        @apply border border-gray-200 w-full flex text-center justify-center items-center tracking-wider;
        font-size: 4px;
      }
    }

    @media (min-width: 768px) {
      .seat-plan-wrapper {
        @apply m-auto flex gap-y-1 flex-row flex-wrap;
        width: 1200px;
        height: 675px;
        margin-bottom: 0px;
      }

      .seat-block {
        @apply p-3 flex justify-center content-center;
      }

      .seat-block-span {
        @apply border border-gray-200 w-full flex text-center justify-center items-center tracking-wider rounded;
        font-size: 14px;
      }
    }

    #__next {
      min-height: 100vh;
      display: flex;
      flex-direction: column;
    }

    .headroom-wrapper {
      height: auto !important;
    }

  }

@layer utilities {
  @variants responsive {
    .kcc-container {
      margin: 30px auto 20px;
      max-width: 1360px;
    }

    .show-left-shades {
      @apply w-10 h-full bg-gradient-to-r from-black absolute z-10 -left-1 top-0;
    }

    .show-right-shades {
      @apply w-10 h-full bg-gradient-to-l from-black absolute z-10 -right-1 top-0;
    }

    .btn {
      @apply text-base font-normal transition transition-all ease-in-out duration-200 rounded-md text-center px-4 pt-2 pb-2 cursor-pointer;
    }

    .btn-hidden {
      display: none !important;
    }
    
    .btn--primary {
      @apply bg-primary-500 border border-primary-500 text-black hover:text-primary-500 hover:bg-transparent;
    }

    .btn--primary:disabled {
      @apply bg-primary-500 opacity-50 pointer-events-none;
    }

    .btn--primary-outline {
      @apply border border-primary-500 text-primary-500 hover:text-black hover:bg-primary-500;
    }

    .btn--primary-outline:disabled {
      @apply pointer-events-none cursor-not-allowed opacity-50;
    }
    
    .btn--secondary {
      @apply bg-gray-200 border border-gray-200 text-black hover:text-gray-200 hover:bg-transparent;
    }

    .btn--secondary-outline {
      @apply border border-gray-200 text-gray-200 hover:text-black hover:bg-gray-200;
    }

    .btn--blue {
      @apply inline-block text-base font-normal bg-blue-100 text-black px-4 pt-2 pb-2 my-4 border border-blue-100 transition transition-all ease-in-out duration-200 rounded-md hover:bg-transparent hover:text-blue-100;
    }

    .btn--blue-extra {
      @apply inline-block text-base font-normal bg-blue-100 text-black px-9 pt-2 pb-2 my-4 border border-blue-100 transition transition-all ease-in-out duration-200 rounded-md hover:bg-transparent hover:text-blue-100;
    }

    .label > span.label{
      @apply text-white pb-3 block;
    }

    .react-select__control {
      @apply border border-gray-200 bg-black !important;
    }

    .react-select__menu {
      position: absolute;
      opacity: 1;
      z-index: 99;
    }

    .react-select__value-container {
      @apply flex items-center !important;
    }

    .form-textarea-validation-error.text-area,
    .form-input-validation-error.text-input {
      @apply border-red-400 !important;
    }

    .text-input {
    @apply border border-gray-200 bg-black rounded-md !important;
    }

    .text-textarea {
    @apply border border-gray-200 bg-black rounded-md !important;
    }
    
    .radio-input {
      @apply border border-gray-200 bg-black rounded-full !important;
    }

    .radio-checkbox {
      @apply border border-gray-200 bg-black rounded-md !important;
    }

    .option-text {
      @apply text-gray-200;
    }

    .kcc-select .react-select__single-value {
      @apply text-white;
    }
  }
}
